<template>
  <div class="tingXiaZComment">
    <div class="tingXiaZComment-t">
      <div class="img"><img :src="postData.topicPicture" alt="" /></div>
      <div class="tingXiaZComment-tr">
        <div class="title">{{ postData.topicTitle }}</div>
        <div class="pro">
          {{ postData.nowStatus }}
        </div>
        <div class="btn">
          <div class="btn-l" @click="toDetail">查看详情</div>
          <div class="btn-r" @click="toOrga">组织架构</div>
        </div>
      </div>
    </div>
    <div class="tingXiaZComment-b">
      <div class="tingXiaZComment-b-main">
        <div class="tingXiaZComment-bt">
          <div class="search-l">
            <div class="img"><img src="../img/search.png" alt="" /></div>
            <div class="input">
              <v-input v-model="topicTitle" placeholder="输入关键字"></v-input>
            </div>
          </div>
          <div class="search-r" @click="toSearch">搜索</div>
        </div>
        <div class="tingXiaZComment-bb" v-if="postData.childrenList">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="list-content"
              v-for="(item, index) in listData"
              :key="index"
              @click="toRouteTitle(item)"
            >
              <div class="item-l">
                <div class="title">{{ item.topicTitle }}</div>
                <div class="people">
                  <div class="img">
                    <img
                      v-for="(oItem, oIndex) in item.imgList"
                      :src="oItem"
                      :key="oIndex"
                      alt=""
                    />
                  </div>
                  <div class="num">{{ item.commentCount || 0 }}人参加</div>
                </div>
                <div class="time">{{ item.createTime }}</div>
              </div>
              <div class="item-c"></div>
              <div class="item-r">
                <div class="img"><img src="../img/comment.png" alt="" /></div>
                <div class="txt">参与讨论</div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL, getTopicInfoByIdURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "tingXiaZComment",
  data() {
    return {
      id: "",
      postData: {},
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      topicTitle: "",
      listData: [],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    toSearch() {
      this.requestData = {
        curPage: 1,
        pageSize: 10,
      };
      this.listData = [];
      this.getPartyList();
    },
    toOrga() {
      this.$router.push({
        name: "organizaStr",
        query: {
          id: this.id,
        },
      });
    },
    toDetail() {
      this.$router.push({
        name: "tingXiaZDetail",
        query: {
          id: this.id,
        },
      });
    },
    toRouteTitle(row) {
      this.$router.push({
        name: "tingXiaZCDetail",
        query: {
          id: row.id,
        },
      });
    },
    onLoad() {
      this.getPartyList();
    },
    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          topicTitle: this.topicTitle,
          tenantId: this.tenantId,
          parentTopicId: this.id,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "65", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getTopicInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaZComment {
  min-height: 100vh;
  .tingXiaZComment-t {
    height: 454px;
    background: url("../img/back.png") no-repeat;
    background-size: 100% 100%;
    padding: 26px 30px 0 22px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .img {
      width: 296px;
      height: 296px;
      flex-shrink: 0;
      border-radius: 20px;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    .tingXiaZComment-tr {
      flex: 1;
      min-width: 0;
      padding-top: 26px;
      .title {
        font-size: 35px;
        color: #ffffff;
        line-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .pro {
        font-size: 26px;
        color: #ffffff;
        line-height: 36px;
        margin: 16px 0;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 16px;
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-l,
        .btn-r {
          width: 168px;
          height: 54px;
          background: #ffffff;
          border-radius: 10px;
          font-weight: 700;
          font-size: 28px;
          color: #fb8435;
          line-height: 54px;
          text-align: center;
        }
      }
    }
  }
  .tingXiaZComment-b {
    padding: 0 30px 30px 28px;
    margin-top: -108px;
    position: relative;
    .tingXiaZComment-b-main {
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      position: relative;
    }
    .tingXiaZComment-bt {
      display: flex;
      position: absolute;
      z-index: 99;
      border-radius: 16px 16px 0 0;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      background: #fff;
      align-items: center;
      padding: 28px 18px 20px 12px;
      justify-content: space-between;
      .search-l {
        width: 590px;
        height: 68px;
        background: #f5f8fb;
        border-radius: 34px;
        display: flex;
        align-items: center;
        padding: 14px 30px;
        box-sizing: border-box;
        .input {
          flex: 1;
        }
        /deep/ .van-cell {
          padding: 0;
          background: none;
        }
        .img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .search-r {
        font-weight: 400;
        font-size: 28px;
        color: #fb8133;
        line-height: 40px;
      }
    }
    .tingXiaZComment-bb {
      padding-top: 120px;
      .list-content {
        padding: 22px 56px 36px 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        justify-content: space-around;
        &:nth-last-child(3) {
          border: 0;
        }
        .item-l {
          width: 368px;
          .title {
            font-weight: 700;
            font-size: 32px;
            color: #000000;
            line-height: 44px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .people {
            display: flex;
            align-items: center;
            margin: 10px 0 8px;
            .img {
              margin-right: 10px;
              img {
                width: 36px;
                height: 36px;
                vertical-align: middle;
              }
            }
            .num {
              font-weight: 400;
              font-size: 24px;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
          .time {
            font-weight: 400;
            font-size: 24px;
            color: #b4b4b4;
            line-height: 34px;
          }
        }
        .item-c {
          width: 2px;
          height: 133px;
          background: #e9e9e9;
        }
        .item-r {
          .img {
            width: 64px;
            height: 52px;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            margin: 0 auto;
            text-align: center;
            margin-top: 18px;
            font-weight: 400;
            font-size: 24px;
            color: #666666;
            line-height: 34px;
          }
        }
      }
    }
  }
}
</style>
